<template>
  <custom-selector
    class="users-hub-selector"
    v-bind="$attrs"
    :items="hubIds"
    :formattedValue="formattedSelectedHubs"
    :getItemLabel="getHubShortCode"
    v-on="$listeners"/>
</template>

<script>
import { mapGetters } from 'vuex'

const CustomSelector = () => import('@components/CustomSelector')

export default {
  name: 'users-hub-selector',
  components: {
    CustomSelector
  },
  props: CustomSelector.props,
  computed: {
    ...mapGetters('hub', [
      'getHubShortCode',
      'hubIds'
    ]),
    formattedSelectedHubs() {
      if (this.$attrs.formattedValue) {
        return this.$attrs.formattedValue
      }

      const isMultiple = this.$attrs.multiple || this.$attrs.multiple === '' // empty string counts as true for props
      if (isMultiple) {
        if (this.$attrs.value) {
          // don't filter if no hubs are selected
          if (this.$attrs.value.length === this.hubIds.length) {
            return this.$t('users.filter.allHubs')
          }
          return this.$attrs.value.map(this.getHubShortCode).join(', ')
        }
      }
      return this.getHubShortCode(this.$attrs.value)
    }
  }
}
</script>
